import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DataGrid, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridEventLookup, GridLogicOperator, GridRenderCellParams, GridRowId, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbar, GridToolbarContainer, MuiEvent } from '@mui/x-data-grid';
import { Box, Button, Chip, Link, Paper } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { collection, query, where, getDocs, getDocsFromCache, writeBatch, doc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import { LinkDoc } from '../Types';
import { getTitleFromUrl } from '../Utils';


// const rows: GridRowsProp = [
//   { id: 1, title: 'aaa', url: 'http://hello.com', tags: [] },
//   { id: 2, title: 'bbb', url: 'http://222.com', tags: ['tag2', 'taggg2'] },
//   { id: 3, title: 'aaccca', url: 'http://333.com', tags: [] },
// ];


const handleClick = () => { }

const columns: GridColDef[] = [
  { field: 'title', headerName: 'title', width: 800, editable: true },
  {
    field: 'url', headerName: 'url', width: 500, editable: true,
    renderCell: (params) => (<Link href={params.value} target="_blank">{params.value}</Link>)
  },
  {
    field: 'tags', headerName: 'tags', width: 300,
    renderCell: (params) => {
      return (
        <>
          {params.value.map((tag: string) => <Chip sx={{ m: 0.5 }} label={tag} variant="outlined" />)}
          <Chip label='+' color="success" variant="outlined" onClick={handleClick} />
        </>
      )
    }
  },
  {
    // https://stackoverflow.com/questions/64331095/how-to-add-a-button-to-every-row-in-mui-x-datagrid
    // https://codesandbox.io/s/c2h7k?file=/src/App.tsx
    field: 'col6',
    headerName: 'Name 6',
    width: 150,
    renderCell: DetailsButton,
    sortable: false,
  },
];

function DetailsButton(props: GridRenderCellParams) {
  // console.log(props)
  const [loading, setLoading] = useState(false)
  const [original, setOriginal] = useState("")
  return (
    <strong>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={loading}
        style={{ marginLeft: 16 }}
        onClick={async () => {
          setLoading(true)
          console.log("setting original to " + props.row.title)
          setOriginal(props.row.title)
          let title = ""
          if (original !== "") {
            title = original
          } else {
            title = await getTitleFromUrl(props.row.url)
          }
          if (title) {
            const linksPath = 'links-s3' // change based on user
            const docRef = doc(db, linksPath, props.row.id)
            updateDoc(docRef, { title: title })

            setTimeout(() => {
              setOriginal("");
            }, 10000);
            // TODO: undo and then timeout (need cache it first otherwise this button is replaced.)
          }
          console.log(title)
          setLoading(false)

        }}
      >
        {loading ? "Loading..." : original ? "Undo" : "Load title"}
      </Button>
    </strong>
  )
}

interface LinksGridProps {
  searchText: string
  rows: LinkDoc[]
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    // const id = randomId();
    setRows((oldRows) => [...oldRows, { name: '', age: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      // [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

// async function queryRows(setRows: Dispatch<SetStateAction<Row[]>>  ) {
//   const linksPath = 'links-s3' // change based on user
//   const linksRef = collection(db, linksPath)
//   const querySnapshot = await getDocs(linksRef);
//   let rows: Row[] = []
//   querySnapshot.forEach((doc) => {
//     // doc.data() is never undefined for query doc snapshots
//     console.log(doc.id, " => ", doc.data());
//     rows.push({ ...doc.data(), id: doc.id })
//   });
//   setRows(rows)
// }


const LinksGrid = ({ searchText, rows }: LinksGridProps) => {


  // const q = db.collection(linksPath)

  // useCollectionData()

  // const links = await getDocs(q) // fromServer?
  // links.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   console.log(doc.id, " => ", doc.data());
  // });


  const filterModel: GridFilterModel = {
    items: [
      { id: 1, field: 'title', operator: 'contains', value: searchText },
    ],
    logicOperator: GridLogicOperator.And,
  };

  const [multiSelect, setMultiSelect] = useState(false)
  const [selectedItems, setSelectedItems] = useState<GridRowId[]>([])

  const onRowsSelectionHandler = (ids: GridRowId[]) => {
    // const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
    setSelectedItems(ids)
  };

  function deleteRows(ids: string[]) {
    const batch = writeBatch(db)
    const linksPath = 'links-s3' // change based on user
    for (const id of ids) {
      const laRef = doc(db, linksPath, id);
      batch.delete(laRef);
    }
    batch.commit();
    setMultiSelect(false)
  }
  return (
    // <div style={{ width: '95%' }} sx={{ mb: 1 }}>
    <Box>
      <div>
        <Button
          sx={{ mb: 1 }}
          onClick={() => setMultiSelect(!multiSelect)}
        >
          Toggle checkbox selection
        </Button>
        <Button
          sx={{ mb: 1 }}
          onClick={() => deleteRows(selectedItems as string[])}
          disabled={selectedItems.length > 0 ? false : true}
        >Delete selected
        </Button>

      </div>
      <DataGrid
        editMode='row'
        checkboxSelection={multiSelect}
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        // onRowEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
        // onRowEditStop={(params, event) => {
        //   console.log(params)
        //   console.log(event)
        // }}
        processRowUpdate={(updatedRow, originalRow) => {
          console.log("updating row: ",  updatedRow)
          const linksPath = 'links-s3' // change based on user
          const docRef = doc(db, linksPath, originalRow.id)
          updateDoc(docRef, { title: updatedRow.title, url: updatedRow.url })
        }}
        // initialState={{
        //   filter: {
        //     filterModel: {
        //       items: [],
        //       quickFilterValues: [],
        //     },
        //   },
        // }}
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        getRowId={row => row.id}
        // filterModel={filterModel}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            // quickFilterProps: { debounceMs: 500 },
          }
        }}
      />
    </Box>
  )
}

export default LinksGrid


