import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import LinksGrid from './components/LinksGrid';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import Search from '@mui/icons-material/Search';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth'
import Content from './components/Content';
import SignIn from './components/SignIn';
import { auth } from './firebase';
import SignOut from './components/SignOut';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9fKgVLGEIFj3h9v2dDZljjdhLVuWigt4",
  authDomain: "links-3e312.firebaseapp.com",
  projectId: "links-3e312",
  storageBucket: "links-3e312.appspot.com",
  messagingSenderId: "885581031714",
  appId: "1:885581031714:web:fa7eb2854f73c8f33edd7b",
  measurementId: "G-RQS8TM4MCD"
};


function App() {
  const [user] = useAuthState(auth)

  return (
    <div>
      <section>
        {user ? (<><SignOut /> <Content /></>) : <SignIn />}
      </section>
    </div>


  );
}

export default App;
