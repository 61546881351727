import axios from 'axios';

export async function getTitleFromUrl(url: string): Promise<string> {
  if (!isUrl(url)) {
    return ''
  }
  try {
    const response = await axios.post('https://script.google.com/macros/s/AKfycbx05on7P_keU4aZRL9AlwEP9fQh6vG6PfUoy0VobJkEWRHUwuzEJl7uKpRPfGmmseht/exec',
      JSON.stringify({
        url: url,
        token: 'SstbxN8IWPv5bT5mMmX1uJs7dxqYdml71ZNmk9ruTvE2gTrWe5p7yyjCkGsFEELe'
      }),
    );
    // const response = await axios.get('/endpoint');
    const html = response.data;
    console.log("aaa", html)
    // const result = JSON.parse(html)
    return html.title
    // const start = html.indexOf('<title>') + '<title>'.length
    // const end = html.indexOf('</title>')
    // return start > 0 ? decodeHtml(html.substring(start, end).trim()) : ''
  } catch (error) {
    console.error('Error fetching or parsing the URL:', error);
    return ''
  }
}

function decodeHtml(html: string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

function isUrl(url: string): Boolean {
  return url.startsWith('http://') || url.startsWith('https://')
}
