import React, { useState } from 'react'
import { TextField, FormControl, InputLabel, Input, FormHelperText, Button } from '@mui/material';
import { getTitleFromUrl } from '../Utils';
import { serverTimestamp, addDoc, collection, writeBatch, doc, Timestamp, } from 'firebase/firestore';
import { db } from '../firebase';
import firebase from "firebase/app";

const lll = [
  { "id": "7", "title": "XXX", "url": "http://www.google.com" },
]

function special() {
  if (true) {
    return
  }
  const batch = writeBatch(db)
  const linksPath = 'links-s3' // change based on user
  for (const item of lll) {
    let entry = {
      title: item.title,
      url: item.url,
      tags: [],
      createdAt: Timestamp.fromMillis(parseInt(item.id) * 10000000)
    }
    const laRef = doc(db, linksPath, item.id)
    batch.set(laRef, entry)
  }
  batch.commit();
}

const AddLink = () => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  async function handleClick() {
    if (title.trim().length > 0) {
      addDocumentOnServer(title, url.trim())
    } else {
      addDocumentOnServer(await getTitleFromUrl(url), url)
    }
    // special()
  };

  async function addDocumentOnServer(title: string, url: string) {
    const linksPath = 'links-s3' // change based on user
    const docRef = await addDoc(collection(db, linksPath), {
      title,
      url,
      createdAt: serverTimestamp(),
      tags: [], // TODO
    });
  }

  return (
    <div>
      <FormControl sx={{ m: 2 }} >
        <TextField sx={{ m: 1 }} onChange={(e) => { setTitle(e.target.value) }} id="input-title" label="Title" variant="standard" style={{ width: 800 }} />
        <TextField sx={{ m: 1 }} onChange={(e) => { setUrl(e.target.value) }} id="input-url" label="url" variant="standard" style={{ width: 800 }} />
        <Button sx={{ m: 1 }} type="submit" onClick={async () => { await handleClick() }} variant="contained" style={{ width: 200 }} >Add</Button>
      </FormControl>
    </div>
  )
}

export default AddLink