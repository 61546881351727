import React from 'react'
import { auth } from '../firebase'

const SignOut = () => {
  return (
    <div>
      <div>UID: { auth.currentUser?.uid }</div>
      <button onClick={() => auth.signOut()}>Sign out</button>
    </div>
  )
}

export default SignOut