import React, { useState } from 'react'
import firebase from 'firebase/app';

import { signInAnonymously, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import { Input, TextField } from '@mui/material';

const SignIn = () => {

  const [email, setEmail] = useState("starkwan@gmail.com");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)

  const signInAnoymounsly = () => {
    signInAnonymously(auth)
  }

  const signInEmailPassword = () => {
    // maybe set fake unchanged email?
    signInWithEmailAndPassword(auth, email, password)
  }

  return (
    <>
      {/* <div>
        <button onClick={signInAnoymounsly}>Sign in</button>
      </div> */}
      <div>
        {/* <input placeholder="Email.." onChange={(e) => setEmail(e.target.value)} /> */}
        <Input
          type='password'
          sx={{ m: 1 }}
          autoFocus
          placeholder='Password...'
          onKeyUp={async e => { if (e.keyCode === 13) { signInEmailPassword() } }}
          onChange={(e) => setPassword(e.target.value)} />
        <Input
          type='submit'
          sx={{ m: 1 }}
          onClick={signInEmailPassword}
          value={loading ? "Loading..." : "Submit"} />
      </div>
    </>
  )
}

export default SignIn