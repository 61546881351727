import { Search } from '@mui/icons-material'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LinksGrid from './LinksGrid';
import AddLink from './AddLink';
import { LinkDoc } from '../Types';
import { collection, getDocs, getDocsFromCache, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';

const Content = () => {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState<LinkDoc[]>([])
  const [isRegistered, setIsRegistered] = useState<Boolean>(false)

  useEffect(() => {
    queryRows()
    console.log("query row")
    listen()
  }, [])

  function listen() {
    console.log('listen')
    const linksPath = 'links-s3' // change based on user
    if (!isRegistered) {
      setIsRegistered(true)
      console.log("registered")
      const unsubscribe = onSnapshot(query(collection(db, linksPath), orderBy('createdAt', 'desc')), (snapshot) => {
        let rows: LinkDoc[] = []
        snapshot.docs.forEach(doc => {
          rows.push({ ...doc.data(), id: doc.id } as LinkDoc)
        });
        setRows(rows)
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("New city: ", change.doc.data());
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
    }
  }


  async function queryRows() {
    const linksPath = 'links-s3' // change based on user
    const linksRef = collection(db, linksPath)

    // This is causing too many queries. needs refactor
    console.log("getDocFromCache")
    const querySnapshot = await getDocs(linksRef);
    let rows: LinkDoc[] = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      rows.push({ ...doc.data(), id: doc.id } as LinkDoc)
    });
    setRows(rows)
  }

  return (
    <div>
      <Box sx={{ height: 400, width: '100%' }}>
        <Typography variant='h3' component='h3' sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
          Manage Users
        </Typography>

        <AddLink />

        <TextField
          id="search"
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }} />

        <Box sx={{ m: 2 }}
          alignItems="center">
          <LinksGrid searchText={searchText} rows={rows} />
        </Box>
      </Box>
    </div>
  )
}

export default Content