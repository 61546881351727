// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9fKgVLGEIFj3h9v2dDZljjdhLVuWigt4",
  authDomain: "links-3e312.firebaseapp.com",
  projectId: "links-3e312",
  storageBucket: "links-3e312.appspot.com",
  messagingSenderId: "885581031714",
  appId: "1:885581031714:web:fa7eb2854f73c8f33edd7b",
  measurementId: "G-RQS8TM4MCD"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
const analytics = getAnalytics(app);
export const db = getFirestore(app);

